// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
  },
  getters: {
  },
  mutations: {
    GET_ABILITY(state, payload) {
      state.respData = []
      if (payload.length > 0) {
        for (let index = 0; index < payload.length; index += 1) {
          const productIndex = state.respData.findIndex(p => p._id === payload[index]._id)
          if (productIndex > -1) {
            Object.assign(state.respData[productIndex], payload[index])
          } else {
            state.respData.push({
              module: payload[index].name,
              subject: payload[index].subject,
              read: false,
              update: false,
              create: false,
              delete: false,
            })
          }
        }
      }
    },
    GET(state, payload) {
      for (let indexPermisstion = 0; indexPermisstion < state.respData.length; indexPermisstion += 1) {
        console.log(state.respData[indexPermisstion])

        for (let index = 0; index < payload.length; index += 1) {
          if (state.respData[indexPermisstion].subject === payload[index].subject) {
            if (payload[index].action === 'read') {
              state.respData[indexPermisstion].read = true
            }
            if (payload[index].action === 'update') {
              state.respData[indexPermisstion].update = true
            }
            if (payload[index].action === 'create') {
              state.respData[indexPermisstion].create = true
            }
            if (payload[index].action === 'delete') {
              state.respData[indexPermisstion].delete = true
            }
          }
        }
      }
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getAbility({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/hero/ability/getAbility')
          .then(response => {
            commit('GET_ABILITY', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/ability/get?id=${item}`)
          .then(response => {
            commit('GET', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/ability/update', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
